import { Tag, Icon } from 'vant'
import Login from '../../intercept/Login.vue'
import Navbar from '../../common/Navbar.vue'
import Share from '../../common/Share.vue'
import Loading from '../../common/Loading.vue'
import LogVisit from '../../common/LogVisit.vue'
import Clue from '../../common/Clue.vue'
import '../../../../assets/icon/font-icon.js'
export default {
    components: {
        Login: Login,
        Share: Share,
        Navbar: Navbar,
        Loading: Loading,
        LogVisit: LogVisit,
        Clue: Clue,
        [Tag.name]: Tag,
        [Icon.name]: Icon
    },
    data () {
        return {
            code: '',
            payOrderCode: '',
            applyCount: 0,
            inform: {}
        }
    },
    mounted () {
        var query = this.$route.query
        this.code = query.code
        this.retrieveInform()
        this.retrievePaidCount()
    },
    methods: {
        async retrieveInform () {
            var pd = { code: this.code }
            var { data: res } = await this.$http.post(this.BMS_URL + '/inform/taskAPC/retrieveTask', this.$qs.stringify(pd))
            if (res.status === '200') {
                console.log(res.data)
                this.inform = res.data
            }
        },
        async payment () {
            this.loadingShow = true
            var serial = window.sessionStorage.getItem(this.SESSION_SERIAL)
            var pd = {
                appKey: 'WPP_MOC',
                amount: this.expense.curPrice,
                attach: '',
                description: '公招报名支付',
                body: '公招报名支付',
                detail: '',
                business: this.code,
                openid: serial
            }
            const { data: res } = await this.$http.post(this.UMS_URL + '/system/pay/createWJsapiPay', this.$qs.stringify(pd))
            if (res.status === '200') {
                var that = this
                this.payOrderCode = res.data.code
                var param = res.data.param
                window.WeixinJSBridge.invoke(
                    'getBrandWCPayRequest', param,
                    function (rs) {
                        if (rs.err_msg === 'get_brand_wcpay_request:ok') {
                            that.retrievePayStatus()
                        } else {
                            that.loadingShow = false
                        }
                    })
            }
        },
        async retrievePayStatus () {
            var that = this
            var pd = { appKey: 'WPP_MOC', code: this.payOrderCode }
            const { data: res } = await this.$http.post(this.UMS_URL + '/system/pay/retrievePayStatus', this.$qs.stringify(pd))
            if (res.status === '200') {
                this.$dialog.alert({
                    title: '提示信息',
                    message: '支付成功'
                })
                that.createOrder()
            } else {
                this.$dialog.alert({
                    title: '提示信息',
                    message: '支付失败'
                })
            }
        },
        async consumePaid (key, count) {
            var userCode = window.sessionStorage.getItem(this.SESSION_USER)
            var pd = { appKey: 'WPP_MOC', paidKey: key, userCode: userCode, count: count }
            const { data: res } = await this.$http.post(this.UMS_URL + '/customer/paid/consumePaid', this.$qs.stringify(pd))
            if (res.status === '200') {
                this.retrievePaidCount()
            }
        },
        async retrievePaidCount () {
            var userCode = window.sessionStorage.getItem(this.SESSION_USER)
            var pd = { userCode: userCode, appKey: 'WPP_MOC', paidKeys: 'WORK_APPLY' }
            const { data: res } = await this.$http.post(this.UMS_URL + '/customer/paid/retrieveUserCusPaidList', this.$qs.stringify(pd))
            if (res.status === '200') {
                this.applyCount = res.data[0].surplusCount
            }
        },
        async createOrder () {
            if (this.applyCount > 0) {
                this.$dialog.confirm({
                    title: '提示信息',
                    message:
                        '查看信息将扣除1次申请次数，是否继续?'
                })
                    .then(() => {
                        this.consumePaid('WORK_VIEW', 1)
                        this.executeCreateOrder()
                    })
            } else {
                this.$dialog.confirm({
                    title: '提示信息',
                    message:
                        '查看次数不足，是否立即充值?'
                })
                    .then(() => {
                        this.buyPaid()
                    })
            }
        },
        async executeCreateOrder () {
            var operator = window.sessionStorage.getItem(this.SESSION_OPERATOR)
            var seller = window.sessionStorage.getItem(this.SESSION_SELLER)
            var referee = window.sessionStorage.getItem(this.SESSION_REFEREE)
            var refereeType = window.sessionStorage.getItem(this.SESSION_REFEREE_TYPE)
            var site = window.sessionStorage.getItem(this.SESSION_SITE)
            var source = window.sessionStorage.getItem(this.SESSION_SOURCE)
            var pd = {
                taskCode: this.code,
                onlineAmount: 0,
                operatorCode: operator,
                sellerCode: seller,
                refereeCode: referee,
                refereeType: refereeType,
                siteCode: site,
                source: source,
                type: 'SGP'
            }
            const { data: res } = await this.$http.post(this.BMS_URL + '/inform/taskAPC/createOrder', this.$qs.stringify(pd))
            if (res.status === '200') {
                this.createOrderClue(res.data.code)
                this.$dialog.alert({
                    title: '提示信息',
                    message: '报名保存成功'
                })
                this.$router.go(-1)
            } else {
                this.$dialog.alert({
                    title: '提示信息',
                    message: '报名保存失败'
                })
            }
            this.loadingShow = false
        },
        createOrderClue (orderCode) {
            var param = { taskCode: this.code, orderCode: orderCode, url: window.location.href }
            var content = '公招公示订单'
            this.$refs.clue.createClue('INFORM_ORDER_INFORM', orderCode, JSON.stringify(param), content)
        },
        buyPaid () {
            this.$router.push({ path: '/work/paid' })
        }
    }
}
